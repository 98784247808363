import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch, matchPath } from "react-router-dom";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { createBrowserHistory } from "history";

import * as Sentry from "@sentry/react";

const history = createBrowserHistory();

const App = React.lazy(() => import("./App"));
const Main = () => {
  return (
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <App />
        <NotificationContainer />
      </Suspense>
    </Provider>
  );
};

// Array of Route Config Objects
// Make sure the order of the routes is correct. The longest url under the same parent should be placed first and in decreasing order.
const routes = [
  { path: "/app/categories/:id" },
  { path: "/app/categories" },
  { path: "/app/add-category" },
  { path: "/app/posts/:id" },
  { path: "/app/posts" },
  { path: "/app/posts/add-post" },

  { path: "/app/images/add-image" },
  { path: "/app/images" },

  { path: "/app/contacts" },

  { path: "/app/urgents/add-urgent" },
  { path: "/app/urgents/:id" },
  { path: "/app/urgents" },

  { path: "/app/programs/add-program" },
  { path: "/programs/:id" },
  { path: "/app/programs" },

  { path: "/app/settings/terms-conditions" },
  { path: "/app/settings/channel-frequencies" },
  { path: "/app/settings/policy" },
  { path: "/app/settings" },

  { path: "/app/live/slider" },
  { path: "/app/tags-categories/add-tag-category" },
  { path: "/app/tags-categories/:id" },
  { path: "/app/tags-categories" },

  { path: "/app/tags/add-tag" },
  { path: "/app/tags/:id" },
  { path: "/app/tags" },

  { path: "/app/orders" },
  { path: "/app/plans/:id" },
  { path: "/app/plans" },

  { path: "/app/all/annoncements" },
  { path: "/app/profile" },
  { path: "/app/change-password" },
  { path: "/user/login" },
  { path: "/user/register" },
  { path: "/user/reset_password" },
  { path: "/user/forgot_password" },

  { path: "/" },
];

Sentry.init({
  dsn: "https://5f93435d23c4724af4a14ab91f2d7e19@o4505946225770496.ingest.us.sentry.io/4507674980712448",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
      routes,
      matchPath,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
ReactDOM.render(<Main />, document.getElementById("root"));

reportWebVitals();
